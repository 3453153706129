@import "../../../node_modules/@angular/material/_theming.scss";
@include mat-core();

$lake-blue: (
  50: #0072AE,
  100: #0072AE, // Your custom hex color
  200: #0072AE,
  300: #0072AE,
  400: #0072AE,
  500: #0072AE,
  600: #0072AE,
  700: #0072AE,
  800: #0072AE,
  900: #0072AE,
  A100: #0072AE,
  A200: #0072AE, // Accent color
  A400: #0072AE,
  A700: #0072AE,
  contrast: (
    50: #FFFFFF,
    100: #FFFFFF, // Contrast color for your custom color
    200: #FFFFFF,
    300: #FFFFFF,
    400: #FFFFFF,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
    A100: #FFFFFF,
    A200: #FFFFFF,
    A400: #FFFFFF,
    A700: #FFFFFF,
  )
);

$forest-green: (
  50: #678E50,
  100: #678E50, // Your custom hex color
  200: #678E50,
  300: #678E50,
  400: #678E50,
  500: #678E50,
  600: #678E50,
  700: #678E50,
  800: #678E50,
  900: #678E50,
  A100: #678E50,
  A200: #678E50, // Accent color
  A400: #678E50,
  A700: #678E50,
  contrast: (
    50: #FFFFFF,
    100: #FFFFFF, // Contrast color for your custom color
    200: #FFFFFF,
    300: #FFFFFF,
    400: #FFFFFF,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
    A100: #FFFFFF,
    A200: #FFFFFF,
    A400: #FFFFFF,
    A700: #FFFFFF,
  )
);

$sun-yellow: (
  50: #FFE472,
  100: #FFE472, // Your custom hex color
  200: #FFE472,
  300: #FFE472,
  400: #FFE472,
  500: #FFE472,
  600: #FFE472,
  700: #FFE472,
  800: #FFE472,
  900: #FFE472,
  A100: #FFE472,
  A200: #FFE472, // Accent color
  A400: #FFE472,
  A700: #FFE472,
  contrast: (
    50: #FFFFFF,
    100: #FFFFFF, // Contrast color for your custom color
    200: #FFFFFF,
    300: #FFFFFF,
    400: #FFFFFF,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
    A100: #FFFFFF,
    A200: #FFFFFF,
    A400: #FFFFFF,
    A700: #FFFFFF,
  )
);

$cloud-grey: (
  50: #E1D9D4,
  100: #E1D9D4, // Your custom hex color
  200: #E1D9D4,
  300: #E1D9D4,
  400: #E1D9D4,
  500: #E1D9D4,
  600: #E1D9D4,
  700: #E1D9D4,
  800: #E1D9D4,
  900: #E1D9D4,
  A100: #E1D9D4,
  A200: #E1D9D4, // Accent color
  A400: #E1D9D4,
  A700: #E1D9D4,
  contrast: (
    50: #FFFFFF,
    100: #FFFFFF, // Contrast color for your custom color
    200: #FFFFFF,
    300: #FFFFFF,
    400: #FFFFFF,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
    A100: #FFFFFF,
    A200: #FFFFFF,
    A400: #FFFFFF,
    A700: #FFFFFF,
  )
);

$app-primary: mat-palette($forest-green, 900);
$app-accent: mat-palette($forest-green, A400);
$app-warn: mat-palette($mat-red);

$default-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($default-theme);

$primary: map-get($default-theme, primary);
$primary-contrast: mat-palette($mat-indigo, 100);
$accent: map-get($default-theme, accent);
$warn: map-get($default-theme, accent);
$white: map-get($default-theme, primary);
$light-grey: mat-palette($mat-grey, 200);
$grey: mat-palette($mat-grey, 500);
$dark-grey: mat-palette($mat-grey, 600);
$background: mat-palette($cloud-grey, 50);

:root {
    --color-primary: #{mat-color($primary)};
    --color-primary-low-alpha: #{mat-color($primary, 0.3)};
    --color-accent: #{mat-color($accent)};
    --color-accent-low-alpha: #{mat-color($accent, 0.3)};
    --color-warn: #{mat-color($app-warn)};
    --color-warn-low-alpha: #{mat-color($warn, 0.6)};
    --color-background: #{mat-color($background, 0.1)};
    --color-light-grey: #{mat-color($light-grey)};
    --color-grey: #{mat-color($grey)};
    --color-dark-grey: #{mat-color($dark-grey)};
    --color-background: #{mat-color($background, 0.1)};
    --color-background-low-alpha: #{mat-color($background, 0.1)};
}



// Example Theme:

// @import "../node_modules/@angular/material/theming";
// @include mat-core();

// $app-primary: mat-palette($mat-grey, 900);
// $app-accent: mat-palette($mat-deep-orange);
// $app-warn: mat-palette($mat-red);

// $default-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// @include angular-material-theme($default-theme);

// $primary: map-get($default-theme, primary);
// $accent: map-get($default-theme, accent);
// $warn: map-get($default-theme, accent);


// :root {
//     --color-primary: #{mat-color($primary)};
//     --color-primary-low-alpha: #{mat-color($primary, 0.3)};
//     --color-accent: #{mat-color($accent)};
//     --color-accent-low-alpha: #{mat-color($accent, 0.3)};
//     --color-warn: #{mat-color($app-warn)};
//     --color-warn-low-alpha: #{mat-color($warn, 0.6)};
// }